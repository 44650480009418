<template>
  <div>
<el-row class="bgW">
      <el-col :span="21">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="店铺名称">
            <el-select
              v-model="search.shopId"
              clearable
              remote
              filterable
              v-loading="loading"
              :remote-method="remoteShop"
              placeholder="请输入店铺名称"
              class="w200"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          
           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          
        </el-form>
      </el-col>
      <el-col :span="3" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
         <el-button type="primary" class="mb10" size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
    </el-row>
    <div>
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="日期" prop="createTime" />
            <ElTableColumn label="顾客姓名" prop="customerName" />
            <ElTableColumn label="现金" prop="cash" />
            <ElTableColumn label="刷卡" prop="payByCar" />
            <ElTableColumn label="微信/支付宝" prop="wechatOrAlipay" />
            <ElTableColumn label="平台收款" prop="platformCollection" />
            <ElTableColumn label="分期" prop="byStages" />
            <ElTableColumn label="转诊" prop="storeTransfer" />
            <ElTableColumn label="欠款" prop="debtMoney" />
            <ElTableColumn label="护理师" prop="name" />
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import pagination from '@/components/Pagination'
import { handleExportExcel } from "@/vendor/handleExportExcel";
import { getShopList } from "@/api/shop";
import { readAdminCapitalStatistics } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  shopId = "";
  start_time = "";
  end_time = "";
}
class Options {
  shop_id = [];
}
export default {
  name: 'OrderList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      options: new Options(), 
      page: new Page(), // 分页
      list: [], // 数据展示
      loading:false,
      exportS: false,
    }
  },
  mounted () {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01' 
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
    
  },
  methods: {
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      readAdminCapitalStatistics({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.loading = false;
      });
    },
    remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 导出表格
    async exportExcel() {
      this.exportS = true
      const { data } = await readAdminCapitalStatistics({
         ...this.search, ...this.page
      });
      handleExportExcel(
        [
          '日期',
          '顾客姓名',
          '现金',
          '刷卡',
          '微信/支付宝',
          '平台收款',
          '分期',
          '转诊',
          '欠款',
          '护理师'
        ],
        [
          'createTime',
          'customerName',
          'cash',
          'payByCar',
          'wechatOrAlipay',
          'platformCollection',
          'byStages',
          'storeTransfer',
          'debtMoney',
          'name'
        ],
        data.list,
        '资金统计'
      );
      this.exportS = false
    },
    
  }
}
</script>

<style lang="scss" scoped>
.title{
  font-weight: bold;
  margin-bottom: 20px;
}
.btnBox{
  display: flex;
  justify-content: flex-end;
  .el-button{
    height: 28px;
  }
}
</style>
